<template>
  <svg
    viewBox="0 0 24 24">
    <path
      d="M12,4 C16.3938683,4 20.1967341,6.36400958 23.4085976,11.0920287 L23.6484177,11.4510374
      C23.8672392,11.784272 23.8672397,12.2156414 23.6484191,12.5488765 C20.3865397,17.5162922
      16.5037333,20 12,20 C7.60613175,20 3.80326589,17.6359904 0.591402424,12.9079713
      L0.35158228,12.5489626 C0.132760829,12.215728 0.132760271,11.7843586 0.35158086,11.4511235
      C3.61346028,6.48370783 7.49626666,4 12,4 Z M12,5 C7.97049418,5 4.46164529,7.17840817
      1.42613628,11.6428125 L1.187,12 L1.41858831,12.3460429 C4.37454667,16.6973573
      7.77699769,18.8803933 11.6740958,18.9952168 L12,19 C16.0295058,19 19.5383547,16.8215918
      22.5738637,12.3571875 L22.812,11.999 L22.5814117,11.6539571 C19.6254533,7.30264272
      16.2230023,5.11960672 12.3259042,5.00478324 L12,5 Z M12,7 C14.7614237,7 17,9.23857625
      17,12 C17,14.7614237 14.7614237,17 12,17 C9.23857625,17 7,14.7614237 7,12 C7,9.23857625
      9.23857625,7 12,7 Z M12,8 C9.790861,8 8,9.790861 8,12 C8,14.209139 9.790861,16 12,16
      C14.209139,16 16,14.209139 16,12 C16,9.790861 14.209139,8 12,8 Z M12,10 C13.1045695,10
      14,10.8954305 14,12 C14,13.1045695 13.1045695,14 12,14 C10.8954305,14 10,13.1045695 10,12
      C10,10.8954305 10.8954305,10 12,10 Z M12,11 C11.4477153,11 11,11.4477153 11,12 C11,12.5522847
      11.4477153,13 12,13 C12.5522847,13 13,12.5522847 13,12 C13,11.4477153 12.5522847,11 12,11 Z">
    </path>
  </svg>
</template>
